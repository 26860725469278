.container-li {
    display: flex;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 80px;
}

.name-quantity {
    width: 200px;
}

.button-monto {
    width: 200px;
    margin-top: 35px;
}

.img {
    width: 100%;
}

.image {
    width: 100px;
}

.container-li .monto {
    font-weight: 600;
}

.container-total {
    display: flex;
    justify-content: space-between;
    padding-left: 200px;
    padding-right: 120px;
}

.container-total .total-pagar {
    width: 200px;
}

.container-total .monto-pagar {
    width: 200px;
}


@media (min-width: 250px) and (max-width: 480px) {
    .image {
        width: 74px;
    }

    .container-li {
        padding-left: 15px;
        padding-right: 15px;
    }

    .button-monto {
        width: 500px;
        margin-top: 5px;
    }

    .container-li .monto {
        font-size: small;
    }

    .container-li {
        font-size: small;
    }

    .name-quantity {
        width: 140px;
    }
    .container-total {
        padding-left: 40px;
        padding-right: 80px;
        font-size: small;
    }

    .container-total .total-pagar {
        width: 80px;
    }

    .container-total .monto-pagar {
        width: 50px;
    }
    .button-monto button{
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center; /* Centrar horizontalmente */
        align-items: center; /* Centrar verticalmente */
    }
    .button-monto button .fa{
        font-size: 13px;
    }
}


@media (min-width: 480px) and (max-width: 600px) {
    .image {
        width: 80px;
    }

    .container-li {
        padding-left: 25px;
        padding-right: 25px;
    }

    .button-monto {
        width: 500px;
        margin-top: 5px;
    }

    .name-quantity {
        margin-right: 35px;
    }

    .container-total {
        padding-left: 100px;
        padding-right: 120px;
    }

    .container-total .total-pagar {
        width: 100px;
    }

    .container-total .monto-pagar {
        width: 100px;
    }
}


@media (min-width: 600px) and (max-width: 769px) {
    .image {
        width: 80px;
    }

    .container-li {
        padding-left: 25px;
        padding-right: 25px;
    }

    .button-monto {
        width: 500px;
        margin-top: 5px;
    }

    .name-quantity {
        margin-right: 35px;
    }

    .container-total {
        padding-left: 70px;
        padding-right: 60px;
    }

    .container-total .total-pagar {
        width: 100px;
    }

    .container-total .monto-pagar {
        width: 100px;
    }
}


@media (min-width: 769px) and (max-width: 1023px) {
    .container-li {
        padding-left: 25px;
        padding-right: 25px;
    }

    .container-total {
        padding-left: 200px;
        padding-right: 120px;
    }

    .container-total .total-pagar {
        width: 100px;
    }

    .container-total .monto-pagar {
        width: 120px;
    }
}


@media (min-width: 1024px) {}